import './index.scss';

function Button(props) {
  const { href="", children } = props;
  console.log("props",props)
    return (
      <div className="btnStyle" data-component="button">
        {href.indexOf('http')===0 && 
        <a className="btn" href={href} rel="noopener noreferrer" target="_blank">
          <span>{children}</span>
        </a>
        }
        {href.indexOf('http')!==0 && 
        <div className="btn" {...props}>
          <span>{children}</span>
        </div>
        }
      </div>
    );
  }
  
export default Button;
  