import { useSelector } from 'react-redux'
import { useEffect, useCallback, useState } from 'react';
import Footer from '@/components/footer';
import { useTranslation } from 'react-i18next';

import getCareerInfo from '@/request/getCareerInfo';
import { useNavigate } from 'react-router-dom';



import "./index.scss"
import ImagePR from '@/components/imagePR';

function Career() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [preTitle, setPreTitle] = useState('')
    const [careerList, setCareerList] = useState([])
    const [curLanguage, setCurLanguage] = useState('')
    // const count = useSelector((state) => state.counter.value)
    const screenType = useSelector((state) => state.global.value)
    const onGoDetail = useCallback((id)=>{
        console.log("执行几次")
        navigate(`/careerDetail/${id}`)
    }, [navigate])

    useEffect(()=>{
      console.log("career::::::",i18n.language)
        setCurLanguage(i18n.language)
        getCareerInfo(i18n.language).then(data=>{
         console.log("拿到数据",data)
         setTitle(data.title)
         setPreTitle(data.pre_title)
         setCareerList(data.list||[])
       })
    },[i18n.language])

    return (
      <>
      <div className="career" style={{backgroundImage:`url(/images/career/${screenType==='pc'?"bg.jpg":"bg_mobile.jpg"})`, backgroundPosition:"50% 50%",backgroundSize:"cover"}}>
        
        <div className="career_content">
            <div className="icon_box_1">
              <ImagePR src="/images/career/icon_1.png"/>
            </div>
            <div className="icon_box_2">
              <ImagePR src="/images/career/icon_2.png"/>
            </div>
            <h2><span className="pre">{preTitle}</span><span className="title">{title}</span></h2>
            <div className="career_list">
                {careerList.map((item,index)=>
                    <div key={index} className="career_item" onClick={()=>{onGoDetail(`${item.id}`)}}>
                        {!(curLanguage==='zh' && screenType==='pc') && <div className="index_box h_v_center">{index+1>9?(index+1):'0'+(index+1)}</div>}
                        <div className="info_wrap">
                        <div className="name">{item.name}</div>
                        <div className="d_r">
                          <div className="alias">{item.alias}</div>
                          <div className="desc">{item.desc}</div>
                        </div>
                        </div>
                    </div>
                )}
                { screenType==='pc' && 
                <div className="career_item">
                    <div className="omit_box">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                </div>
                }
            </div>
        </div>
        <div className="foot_container">
            <Footer></Footer>
        </div>
      </div>
      </>
    );
  }
  
  export default Career;
  