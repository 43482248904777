import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux'
import Footer from '@/components/footer';
import { useParams, useNavigate, useLoaderData, useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import util from '@/utils/index';
import ImagePR from '@/components/imagePR';

import getDetailInfo from '@/request/getDetailInfo';

import "./index.scss"


function Detail({props}) {
    const { id } = useParams();
    const { i18n } = useTranslation();
    const _screenType = useSelector((state) => state.global.value)
    console.log("什么参数",id)

    const [detailInfo, setDetailInfo] = useState({})
    const [worksList, setWorksList] = useState([])
    // const shuchu = useCallback(()=>{
    //     console.log("itoa吧")
    // },[])
    const navigate = useNavigate()
    // const matches = useMatches();
    // const albums = useLoaderData();

    const onGoBack = useCallback(()=>{
        navigate(`/works`)
        // let mm =  matches('/works')
        // console.log("mm:",mm)
        console.log("ddwdw",window.history.length)
        console.log("this:",this)
        console.log("props:",props)
        // navigate(-3)
    },[navigate])

    const onGoPrev = useCallback(()=>{
      
      const prevId = util.getPrevId(id,worksList)
      console.log("prev",prevId)
      if(prevId){
        navigate(`/detail/${prevId}`)
      }
    },[navigate,worksList,id])

    const onGoNext = useCallback(()=>{
      console.log("next")
      const nextId = util.getNextId(id,worksList)
      if(nextId){
        navigate(`/detail/${nextId}`)
      }
    },[navigate,worksList,id])

    const init = useCallback(()=>{
      const _works =JSON.parse(util.getCache("works"))
      setWorksList(_works)
    },[])

    useEffect(()=>{
       getDetailInfo(i18n.language).then(data=>{
        console.log("拿到数据",data)
        setDetailInfo(data[id])
      })
      init()
    },[id,init,i18n.language])
    return (
      <>
      {Object.keys(detailInfo).length && 
      <div className="detail">
        <div className="banner" style={{backgroundImage:`url(${_screenType==='pc'?detailInfo.banner:detailInfo.banner_mobile})`, backgroundPosition:"50% 50%",backgroundSize:"cover"}}></div>
        <div className="content">
            {detailInfo.title && <div className="title">{detailInfo.title}</div>}
            {detailInfo.desc && <div className="desc">{detailInfo.desc}</div>}
            <div className="info_wrap">
              {detailInfo.address && 
              <span className="address">{detailInfo.address}</span>
              }
              {detailInfo.date && 
              <span className="date">{detailInfo.date}</span>
              }
            </div>
            {detailInfo.content &&
            <div className="con_value">
                {detailInfo.content.map(item=>
                   <>
                   {item.type==="video" && <video className="video" controls style={{width:"100%"}} src={item.src}></video>}
                   {/* {item.type==="iframe" && <iframe className="iframe" src={item.src} frameborder="0" allow="autoplay" title="Multimedia Interactive Experience Theme Classroom"></iframe>} */}
                   {item.type==="iframe" && <a className="iframe" href={item.src} target="_blank" title="234"><ImagePR className="image" src={item.poster}/></a>}
                   {item.type==="text" && <p className="text">{item.value}</p>}
                   {item.type==="image" && <ImagePR className="image" src={item.src}/>}
                   </>
                )}
            </div>
            }
            <div className="paging_wrap">
              <button onClick={onGoBack}>LIST</button>
              {/* <div>
                <button onClick={onGoPrev}>prev</button>
                <button onClick={onGoNext}>next</button>
              </div> */}
            </div>
        </div>
        
        <div className="foot_container">
            <Footer></Footer>
        </div>
      </div>
       }
      </>
    );
}
  
  export default Detail;