import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import ImagePR from '@/components/imagePR';

import "./index.scss"

function Footer() {
    const { t } = useTranslation();
    return (
        <footer className="foot_wrap">
           <div className="icon_wrap">
                <ul>
                    <li><ImagePR src="/images/foot/be.png"/></li>
                    <li><ImagePR src="/images/foot/video.png"/></li>
                    <li><ImagePR src="/images/foot/v.png"/></li>
                    <li><ImagePR src="/images/foot/wechat.png"/></li>
                </ul>
           </div>
           <div className="foot_nav">
            <ul>
                <li><NavLink to="/">{t('methods.nav_home')}</NavLink></li>
                <li><NavLink to="/about">{t('methods.nav_about')}</NavLink></li>
                <li><NavLink to="/works">{t('methods.nav_works')}</NavLink></li>
                <li><NavLink to="/career">{t('methods.nav_career')}</NavLink></li>
                <li><NavLink to="/contact">{t('methods.nav_contact')}</NavLink></li>
            </ul>
           </div>
           <div className="copy_wrap">
            <text>{t('methods.copyright')}</text>
           </div>
        </footer>
    );
}
  
export default Footer;
