/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

import "./index.scss"

function Pagination(props) {
    //  const { t } = useTranslation();
    const { total, defaultCurrent=1, defaultPageSize=10, onChange } = props
    const [pageArr,setPageArr] = useState([])
    const [current,setCurrent] = useState(defaultCurrent)
    console.log(props)

    const initPagination = useCallback(() =>{
        const _len = Math.ceil(total/defaultPageSize)
        const _pageArr = []
        for(let i=1; i<=_len; i++){
            _pageArr.push(i)
        }
        console.log("_pageArr",_pageArr)
        setPageArr(_pageArr)
    },[defaultPageSize,total])

    const _onChange = (index) => {
        if(current===index) return
        setCurrent(index)
        if(onChange && typeof onChange === "function") onChange(index)
    }
    useEffect(()=>{
        initPagination()
        setCurrent(defaultCurrent)
    },[initPagination,defaultCurrent])
    
    return (
        <section className="pagination">
            {pageArr.length>1 &&
            <ul>
            {pageArr.map(item=>
                <li className={current===item?'active':''} onClick={()=>{_onChange(item)}}><a rel="nofollow">{item}</a></li>
            )}
            </ul>
            }
        </section>
    );
}
  
export default Pagination;
