import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function LangBox() {
    const { i18n } = useTranslation();
    const onChangeLang = useCallback(()=>{
        console.log("当前语言：",i18n.language)
        i18n.changeLanguage(i18n.language==='zh'?'en':'zh');
        document.documentElement.dataset.language = i18n.language
    },[i18n])
    useEffect(()=>{
        console.log("初始语言：",i18n.language)
        // i18n.changeLanguage(i18n.language);
        document.documentElement.dataset.language = i18n.language
    },[i18n.language])
    return (
        <div className="lang_wrap v_center flex_end" onClick={onChangeLang}>
            {i18n.language==='en'?
            <div><span>EN</span>/CN</div>
            :
            <div>EN/<span>CN</span></div>
            }
        </div>
    );
  }
  
export default LangBox;
  