import { useSelector } from 'react-redux'
import { useEffect, useCallback, useState } from 'react';
import Footer from '@/components/footer';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import ImagePR from '@/components/imagePR';

import getCareerInfo from '@/request/getCareerInfo';


import "./index.scss"


function CareerDetail() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const { id } = useParams();
    console.log("什么参数",id)

    const [title, setTitle] = useState('')
    const [preTitle, setPreTitle] = useState('')
    const [detailInfo, setDetailInfo] = useState({})
    const screenType = useSelector((state) => state.global.value)
    // const shuchu = useCallback(()=>{
    //     console.log("itoa吧")
    // },[])
    const onGoBack = useCallback(() =>{
      navigate(-1)
    },[navigate])

    useEffect(()=>{
      getCareerInfo(i18n.language).then(data=>{
        setTitle(data.title)
        setPreTitle(data.pre_title)
        const _list = data.list
        console.log("id",id)
        for(let item of _list){
          if(item.id.toString() === id){
            console.log("item",item)
            setDetailInfo(item)
          }
        }
      })
    },[id,i18n.language])
    return (
      <>
      <div className="career_detail" style={{backgroundImage:`url(/images/career/${screenType==='pc'?"bg.jpg":"bg_mobile.jpg"})`, backgroundPosition:"50% 50%",backgroundSize:"cover"}}>
        
        <div className="detail_content">
            <div className="icon_box_1">
              <ImagePR src="/images/career/icon_1.png?v=1.0"/>
            </div>
            <div className="icon_box_2">
              <ImagePR src="/images/career/icon_2.png?v=1.0"/>
            </div>
            <h2><span className="pre">{preTitle}</span><span className="title">{title}</span></h2>
            <div className="detail_box">
              <div className="header">
                {detailInfo.name && <div className={classNames('title',{'font_48':detailInfo.name.length>17})}>{detailInfo.name} </div>}
                <div className="d_r">
                  <div className="alias">{detailInfo.alias}</div>
                  <div className="desc">{detailInfo.desc}</div>
                </div>
              </div>
              <div className="context">
                {detailInfo.detail && detailInfo.detail.map(item =>
                <div className="con_item">
                  <h5>{item.title}</h5>
                  <div>
                  {item.content.map(_item =>
                  <p dangerouslySetInnerHTML={ {__html: _item} }></p>
                  )}
                  </div>
                </div>
                )}
              </div>
            </div>
            <div className="btn_back h_v_center" onClick={onGoBack}>LIST</div>
            {/* {detailInfo.content && detailInfo.content[i18n.language] && 
            <div className="desc">
                {detailInfo.content[i18n.language].map(item=>
                    <p>{item}</p>
                )}
            </div>
            } */}
        </div>
        <div className="foot_container">
            <Footer></Footer>
        </div>
       
      </div>
      
      </>
    );
}
  
  export default CareerDetail;