import Home from '@/views/home';
import About from '@/views/about';
import Works from '@/views/works';
import Career from '@/views/career';
import Contact from '@/views/contact';
import Detail from '@/views/detail';
import CareerDetail from '@/views/careerDetail';
  
const routes = [
  {
    path:'/',
    element: <Home />
  },
  {
    path:'/about',
    element: <About />,
    // children: [
    //   {
    //     path: '',
    //     element: <div>前台首页</div>
    //   },
    //   {
    //     path: 'a',
    //     element: <div>rec-aaa</div>
    //   }
    // ]
  },
  {
    path:'/works',
    element: <Works />,
  },
  {
    path:'/career',
    element: <Career />,
  },
  {
    path:'/contact',
    element: <Contact />,
  },
  {
    path:'/detail/:id',
    element: <Detail />,
  },
  {
    path:'/careerDetail/:id',
    element: <CareerDetail />,
  },
  {
    path: '*',
    element: <div>前台404</div>,
  }
];

export default routes
  