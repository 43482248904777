// import { useSelector, useDispatch } from 'react-redux'
// import { decrement, increment } from '@/features/counter/counterSlice'
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ImagePR from '@/components/imagePR';
import Button from '@/components/button';


import getHomeInfo from '@/request/getHomeInfo';

import 'swiper/css';
import 'swiper/css/pagination';
import './index.scss';


function Home() {

  // const count = useSelector((state) => state.counter.value)
  // const dispatch = useDispatch()
  const { i18n } = useTranslation();
  const screenType = useSelector((state) => state.global.value)
  console.log(i18n.language)
  const [swiperList, setSwiperList] = useState([])
  const navigate = useNavigate()

  const onGoDetail = useCallback((id)=>{
    navigate(`/detail/${id}`)
  },[navigate])

  useEffect(()=>{
    getHomeInfo(i18n.language).then(data=>{
     console.log("拿到数据",data)
     setSwiperList(data.list)
   })
 },[i18n.language])

  return (
    <div className="home">
      {swiperList.length>0 && 
      <Swiper
        direction={'vertical'}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {swiperList.map(item=>(
        <SwiperSlide key={item.title}>
         <div className="slide" style={{backgroundImage:`url(${screenType==='pc'?item.bg:item.bg_mobile})`,backgroundSize:"cover",backgroundPosition:"50%,50%"}}>
         <div className="black-mask">
          {screenType==='pc' && item.video && <div className="video_wrap h_v_center">
            <video className="case_video" src={item.video} muted loop autoPlay></video>
          </div>
          }
          <div className="text _text_wrap">
            <div className="text-wrap _text fr-view">
              <div className="slide-type-box h_v_center un_fadeInUp animated">
                {/* {item.logo && 
                <div className="slide-logo-wrap h_v_center">
                  <ImagePR src={item.logo}/>
                </div>
                } */}
                {/* {item.tag && 
                <div className="slide-type-wrap ls3">{item.tag[i18n.language]}</div>
                } */}
              </div>
              {item.title && 
              <div className="slide-slogan-box ls3 h_v_center un_fadeInUp animated">
                <text>{item.tag}</text>
                <text>{item.title}</text>
              </div>
              }
              {item.address &&
              <div className="slide-address-box ls3 h_v_center un_fadeInUp animated delay-02s">
                <text>{item.address}</text>
                <text>{item.date}</text>
              </div>
              }
              {item.btn && 
              <div className="btn-box un_fadeInUp animated delay-04s">
                <Button onClick={()=>{onGoDetail(item.btn.link)}} value="view details">{item.btn.text}</Button>
              </div>
              }
            </div>
          </div>
         </div>
         </div>
        </SwiperSlide>
        ))}
      </Swiper>
      }
      {/* <div>
        <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <span>{count}</span>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(decrement())}
        >
          Decrement
        </button>
      </div> */}
    </div>
  );
}

export default Home;
