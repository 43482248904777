import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setTag } from '@/features/tag/tagSlice'
import Footer from '@/components/footer';
import Pagination from '@/components/pagination'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import util from '@/utils/index';
import classNames from "classnames";

import getWorksInfo from '@/request/getWorksInfo';

import "./index.scss"

import zw from "@/assets/images/zw.png"
import ImagePR from '../../components/imagePR';


function Works() {
    const { t, i18n } = useTranslation();
    const [worksObj, setWorksObj] = useState({})
    const [worksList, setWorksList] = useState([])
    const [tagList, setTagList] = useState([])
    const [curTag, setCurTag] = useState("")
    const [curIndex, setCurIndex] = useState(null)
    const [defaultPageSize] = useState(10)
    const [total, setTotal] = useState(0)

    const _curTag = useSelector((state) => state.tag.value)||'all'
    const _screenType = useSelector((state) => state.global.value)
    console.log("_screenType",_screenType)
    const dispatch = useDispatch()
    console.log("_tag",_curTag)
    const navigate = useNavigate()

    const onGoDetail = useCallback((id)=>{
        console.log("执行几次")
        // 设置下当前列表缓存
        console.log("咔咔咔",worksList)
        util.setCache("works",JSON.stringify(worksList))
        navigate(`/detail/${id}`)
        
    }, [navigate,worksList])

    const getWorksList = useCallback((tag,index) =>{
        let allWorksList = []
        if(tag==='all'){
            for(let key in worksObj){
                allWorksList = [...allWorksList,...worksObj[key]]
            }
        }else{
            allWorksList = worksObj[tag]
        }
        console.log(index*defaultPageSize,tag,worksObj)
        console.log("allWorksList",allWorksList)
        setTotal(allWorksList.length)
        let curWorksList = [...allWorksList].splice((index-1)*defaultPageSize,defaultPageSize)
        console.log("curWorksList:",curWorksList)
        setWorksList(curWorksList)

    },[defaultPageSize,worksObj])

    const onPaginationChange = useCallback((args)=>{
        console.log("页面变化",args)
        setCurIndex(args)
    },[])


    const onChangeTag = useCallback((tag)=>{
        setCurTag(tag)
        onPaginationChange(1)
        // getWorksList(tag,1)
        console.log("开始设置tag",tag)
        dispatch(setTag({value:tag}))
        
    }, [dispatch,onPaginationChange])

    

    
    
    useEffect(()=>{
        getWorksInfo(i18n.language).then(data=>{
         console.log("拿到数据",data)
         setWorksObj(data)
       })
    },[i18n.language])

    useEffect(()=>{
        if(!Object.keys(worksObj).length)return
        let allTagList = ["all"]
        for(let key in worksObj){
           allTagList.push(key)
        }
        console.log("allTagList：",allTagList)
        setTagList(allTagList)
        onChangeTag(_curTag)
    },[worksObj,_curTag,onChangeTag])

    useEffect(()=>{
        if(!Object.keys(worksObj).length)return
        if(!curIndex) return
        if(!curTag) return
        getWorksList(curTag,curIndex)
    },[worksObj,curTag,curIndex,getWorksList])


    return (
      <>
      <div className={classNames(["works",_screenType+"_works"])}>
        {_screenType==="pc" && 
        <div className="banner h_v_center" style={{backgroundImage:"url(images/works/bg.jpg)", backgroundPosition:"50% 50%",backgroundSize:"cover"}}>
            <div className="slogan-wrap"><ImagePR src="images/works/slg.png"/></div>
        </div>
        }
        {_screenType!=="pc" && 
        <div className="banner">
            <div className="slogan-wrap">ALL</div>
        </div>
        }       
        <div className="content">
            <div className="sub_nav">
                {tagList.map((item, index) =>
                    <div key={index} className="sub_nav_item">
                        <a className={item===curTag?"active":""} onClick={()=>{onChangeTag(item)}}>{t(`methods.tag_${item}`)}</a>
                    </div>
                )}
            </div>
            <div className="works_list">
                {worksList.map((item, index) => 
                    <div key={index} className="works_list_item" style={{backgroundImage:`url(${item.bg})`, backgroundSize:"cover", backgroundPosition:"50%,50%"}}>
                        <img className="img" src={zw} title="blank" alt=""/>
                        <div className="desc" onClick={()=>{onGoDetail(`${item.tag}_${item.id}`)}}>
                            <div>{item.title}</div>
                            <div>{item.desc}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="paging-wrap">
                <Pagination total={total} defaultPageSize={defaultPageSize} defaultCurrent={curIndex} onChange={onPaginationChange}/>
            </div>
        </div>
      </div>
      <Footer></Footer>
      </>
    );
  }
  
  export default Works;