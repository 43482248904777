import Header from '@/components/header';
import { useSelector, useDispatch } from 'react-redux';
import { setInfo } from '@/features/global/globalSlice';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import './App.scss';
import { useCallback, useEffect,useState } from 'react';
import util from '@/utils/index';


function App() {

  const [screen, setScreen] = useState("")
  const elements = useRoutes(routes)
  const screenType = useSelector((state) => state.global.value)
  const dispatch = useDispatch()

  window.onresize = () =>{
    setScreenType()
  }

  const setScreenType = () => {
    let _screen = ""
    if(util.getScreenWidth() < 450){
      screenType !=='mobile' && dispatch(setInfo({value:"mobile"}))
      _screen = "mobile";
    }else if(util.getScreenWidth() < 769 ){
      screenType !=='pad' && dispatch(setInfo({value:"pad"}))
      _screen = "pad";
    }else{
      screenType !=='pc' && dispatch(setInfo({value:"pc"}))
      _screen = "pc";
    }
    document.documentElement.dataset.screen = _screen
    setScreen(_screen)
  }

  useEffect(()=>{
    setScreenType()
  },[])

  return (
    <>
    {/* {screen==='pc' && 
      <div className="maintenance">站点正在维护中...</div>
    }
    {screen!=='pc' &&  */}
      <Header/>
      <div className="app_container">{elements}</div>
    {/* } */}
    </>
  );
}

export default App;
