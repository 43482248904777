import { useCallback,useEffect,useState } from "react";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import classNames from "classnames";


function Menu() {
    const { t } = useTranslation();
    const [navCurClass, setNavCurClass] = useState("")
    const [maskCurClass, setMaskCurClass] = useState("")
    const screenType = useSelector((state) => state.global.value)
    console.log("screenType",screenType)
    const onShowMenu = useCallback(() => {
        console.log("点到了")
        if(screenType==="pc") return
        setNavCurClass("slideInLeft")
        setMaskCurClass("nav_mask_fadeIn")
    },[screenType])
    const onHideMenu = useCallback(() => {
        console.log("为啥",screenType)
        if(screenType==="pc") return
        setNavCurClass("slideOutLeft")
        setMaskCurClass("nav_mask_fadeOut")
    },[screenType])
    

    const resetInit = useCallback(() => {
        console.log("重置了",navCurClass!=="",maskCurClass!=="")
        navCurClass!=="" && setNavCurClass("");
        maskCurClass!=="" && setMaskCurClass("");
    },[navCurClass,maskCurClass])

    useEffect(()=>{
        console.log("进来了")
        if(screenType==="pc"){
            resetInit()
            return
        }
    },[screenType,resetInit])
    return (
        <>
        <div className="nav_show_btn" onClick={onShowMenu}>
            <span></span>
            <span></span>
            <span></span>
        </div>
      <div className="nav_container">
        
        <div className={classNames(["nav_mask", maskCurClass, "animated","fast"])} onClick={onHideMenu}></div>
        <div className={classNames(["nav_box",navCurClass,"animated","fast"])}>
            <ul onClick={onHideMenu}>
                <li><NavLink to="/">{t('methods.menu_home')}</NavLink></li>
                <li><NavLink to="/about">{t('methods.menu_about')}</NavLink></li>
                <li><NavLink to="/works">{t('methods.menu_works')}</NavLink></li>
                <li><NavLink to="/career">{t('methods.menu_career')}</NavLink></li>
                <li><NavLink to="/contact">{t('methods.menu_contact')}</NavLink></li>
            </ul>
            <div className="nav_close_btn" onClick={onHideMenu}>
                <span></span>
                <span></span>
            </div>
        </div>
      </div>
      </>
    );
}
  
export default Menu;
  