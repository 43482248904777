import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ImagePR from '@/components/imagePR';


import { useCallback, useEffect } from 'react';

function Logo() {

  const navigate = useNavigate()
  const screenType = useSelector((state) => state.global.value)

  const onGoHome = useCallback(()=>{
    navigate("/")
  },[navigate])
    return (
      <div className="logo_wrap" onClick={onGoHome}>
        {screenType==="pc" && 
        <>
        <ImagePR className="logo_w" src="/images/logo_w.png"/>
        <ImagePR className="logo_b" src="/images/logo_b.png"/>
        </> 
        }
        {screenType!=="pc" && 
          <ImagePR src="/images/logo_b.png"/>
        }
        
      </div>
    );
}
  
export default Logo;
  