import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: "",
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setInfo: (state, action) => {
      const { value } = action.payload
      console.log("收到",action.payload)
      state.value = value
    },
  },
})

// Action creators are generated for each case reducer function
export const { setInfo } = globalSlice.actions

export default globalSlice.reducer