import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react';
import { Swiper , SwiperSlide } from 'swiper/react';
import {EffectFade, Mousewheel, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import Footer from '@/components/footer';

import getAboutInfo from '@/request/getAboutInfo';

import 'swiper/css';
import 'swiper/css/effect-fade';

import "./index.scss"
import ImagePR from '@/components/imagePR';

function About() {

  const { i18n } = useTranslation();
  console.log(i18n.language)
  const [swiperList, setSwiperList] = useState([])
  const [mousewheelstate, setMousewheelstate] = useState(true)
  const screenType = useSelector((state) => state.global.value)

  useEffect(()=>{
    getAboutInfo(i18n.language).then(data=>{
     console.log("拿到数据",data)
     setSwiperList(data.list)
   })
 },[i18n.language])

    return (
      <>
      <div className="about" style={{backgroundImage:`${screenType==='pc'?"url(images/about/bg.jpg)":"url(images/about/bg_mobile.jpg)"}`, backgroundPosition:"50% 50%",backgroundSize:"cover"}}>
      <Swiper
        direction={'vertical'}
        mousewheel={mousewheelstate}
        pagination={{
          clickable: true,
        }}
        effect={'fade'}
        modules={[EffectFade,Mousewheel,Pagination]}
        className="mySwiper swiper-v"
        onSlideChange={() => console.log('slide change')}
        // onSlideChangeTransitionStart={() => setMousewheelstate(false)}
        // onSlideChangeTransitionEnd={() => setMousewheelstate(true)}
        onSwiper={(swiper) => console.log('s::',swiper)}
        noSwiping={true}
      >
        {swiperList.map((item,index)=>(
        <SwiperSlide key={item.title}>
            <div className="index_box slide_left animated">
              {index===0 && <ImagePR src="images/about/type_1.png"/>}
              {[1,2,3,4,5,6].includes(index) && <ImagePR src="images/about/type_2.png"/>}
              {index===7 && <ImagePR src="images/about/type_3.png"/>}
              {index===8 && screenType!=='pc' && <ImagePR src="images/about/type_3.png"/>}
            </div>
            {item.type===1 && 
            <div className={classNames("slide",`slide_${index}`)}>
                <div className="slide_left animated">
                    <div className="title">{item.title}</div>
                    <div className="introduction" dangerouslySetInnerHTML={{__html:item.introduction}}></div>
                    <div className="desc" dangerouslySetInnerHTML={{__html:item.desc}}></div>
                </div>
            </div>
            }
            {item.type===2 && 
            <div className={classNames("slide",`slide_${index}`)}>
                <div className="img_box">
                <ImagePR src={item[screenType==='pc'?'list_pc':'list_mobile']}/>
                </div>
            </div>
            }
            
            {index>7 && 
            <div className="foot_container">
                <Footer></Footer>
            </div>
            }
        </SwiperSlide>
        ))}
      </Swiper>
      
      </div>
      </>  
    );
  }
  
  export default About;
  