import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: "all",
}

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setTag: (state, action) => {
        
        const { value } = action.payload
        console.log("收到",action.payload)
      state.value = value
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTag } = tagSlice.actions

export default tagSlice.reducer