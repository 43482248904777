import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'

import Footer from '@/components/footer';
import { useTranslation } from 'react-i18next';

import getContact from '@/request/getContact';

import "./index.scss"


function Contact() {

    const { t, i18n } = useTranslation();
    // const count = useSelector((state) => state.counter.value)
    const [contactInfo, setContactInfo] = useState([])
    const [curLanguage, setCurLanguage] = useState('')
    const screenType = useSelector((state) => state.global.value)
    console.log("screenType",screenType)
    useEffect(()=>{
        console.log("career::::::",i18n.language)
        setCurLanguage(i18n.language)
        getContact(i18n.language).then(data=>{
           console.log("拿到数据",data)
           setContactInfo(data)
         })
    },[i18n.language])
    return (
    <>
    {screenType==='pc' && 
      <div className="contact" style={{backgroundImage:"url(images/contact/bg.jpg)", backgroundPosition:"50% 50%",backgroundSize:"cover"}}>
        <div className="contact_con">
            <div className="header">
                {curLanguage==='zh' && 
                <>
                <div className="h_l">{contactInfo.name}</div>
                <div className="h_r">
                    {contactInfo.city}<br/>
                    {contactInfo.definition}
                </div>
                </>
                }
                {curLanguage==='en' && 
                <div className="h_r">
                SHANGHAI Head Office
                </div>
                }
            </div>
            <div className="foot h_v_center">
                <div className="info">
                    <div className="address">{contactInfo.address}</div>
                    <div className="email">{contactInfo.email}</div>
                    <div className="phone">{contactInfo.phone}</div>
                </div>
            </div>
        </div>
        <div className="regular reg_left"></div>
        <div className="regular reg_right"></div>
        <div className="door d_left door_left_move delay-1s animated"></div>
        <div className="door d_right door_right_move delay-1s animated"></div>
        <div className="foot_container">
            <Footer></Footer>
        </div>
      </div>
    } 
    {screenType!=='pc' && 
    <div className="contact" style={{backgroundImage:"url(images/contact/bg_mobile.jpg)", backgroundPosition:"50% 50%",backgroundSize:"cover"}}>
       <div className="contact_con">
            <div className="header">
                {curLanguage === 'zh' && 
                <>
                <div className="h_r">{contactInfo.city} {contactInfo.definition}</div>
                <div className="h_l">{contactInfo.name}</div>
                </>
                }
                {curLanguage !== 'zh' && 
                <>
                <div className="h_b">{contactInfo.city_mobile}</div>
                <div className="h_b">{contactInfo.definition_mobile}</div>
                </>
                }
            </div>
             <div className="foot">
                <div className="info">
                    <div className="address">{contactInfo.address}</div>
                    <div className="email">{contactInfo.email}</div>
                    <div className="phone">{contactInfo.phone}</div>
                </div>
             </div>
        </div>
        <div className="foot_container">
            <Footer></Footer>
        </div>
    </div>
    }
    </>
    );
  }
  
  export default Contact;
  