
function getWorksInfo(language) {
  return new Promise(function (resolve, reject) {
    fetch(`/json/works_${language}.json`).then(function (response) {
        if(response.status === 200){
            resolve(response.json())
        }else{
            reject()
        }
    })
  })

}

export default getWorksInfo;