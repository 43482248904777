import { useCallback, useEffect, useState } from "react";
import config from '@/config/index'
import { startsWith } from 'lodash-es'

function ImagePR({ src, ...props }) {

  console.log("logo::",src,props)
  const [curSrc, setSrc] = useState('');
  const getAbsolutelyUrl = useCallback((src)=>{
    if(startsWith(src, 'images/')){
      return config.prefix + src
    }
    if(startsWith(src, 'http')){
      return src
    }
    if(startsWith(src, 'data:image/')){
      return src
    }
    if(startsWith(src, '/images/')){
      return src
    }
  },[])

  useEffect(()=>{
   const _src = getAbsolutelyUrl(src)
   setSrc(_src)
  },[getAbsolutelyUrl,src])

  return (
    <>
      <img src={curSrc} alt="" {...props}/>
    </>
  )
}

export default ImagePR
