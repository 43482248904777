import {useEffect, useState} from "react";
import Logo from '@/components/logo';
import Menu from '@/components/menu';
import LangBox from '@/components/langBox';
import { useLocation } from 'react-router-dom';
import classNames from "classnames";
import util from '@/utils/index';

import './index.scss';

function Header() {
  console.log("加载了头部")
  const [containerClass, setContainerClass] = useState('header_container')
  const [pathClass, setPathClass] = useState('')
  const location = useLocation();
  
 console.log("宽地",util.getScreenWidth()) 

 let classObj = {
  header_container:true,
  wrap_bg:false
}
 useEffect(()=>{
  const { pathname } = location;
  if(pathname){
    console.log("pathname",pathname.slice(1))
    setPathClass("menu_"+pathname.slice(1))
  }
  setContainerClass(classNames(classObj))
},[location])
 
 if(util.getScreenWidth()>678){
  window.onscroll = function () {
    let scrollPos = util.getScrollTop();
    if (scrollPos === 0) {
      if(classObj.wrap_bg !== false){
        classObj.wrap_bg = false
        setContainerClass(classNames(classObj))
      }
    }else{
      if(classObj.wrap_bg !== true){
        classObj.wrap_bg = true
        setContainerClass(classNames(classObj))
      }
    }
  }
 }
  
  console.log("location",location)
    return (
      <div className={containerClass}>
        <div className={classNames('header_wrap',pathClass)}>
          <Logo/>
          <Menu/>
          <LangBox/>
        </div>
      </div>
    );
  }
  
export default Header;
  