/**
 * 获取滚动高度
 * @returns {number} - 滚动高度值
*/
function getScrollTop() {
    var scrollPos;
    if (window.pageYOffset)
    {
        scrollPos = window.pageYOffset;
    }
    else if (document.compatMode && document.compatMode !== 'BackCompat')
    {
        scrollPos = document.documentElement.scrollTop;
    }
    else if (document.body)
    {
        scrollPos = document.body.scrollTop;
    }
    return scrollPos;
}
/**
 * 获取屏幕宽度
 * @returns {number} - 屏幕的宽度
*/
function getScreenWidth() {
    let windowWidth = window.innerWidth;
    return windowWidth
}
/**
 * 设置缓存
*/
function setCache(key, value) {
    localStorage.setItem(key, value)
}
/**
 * 获取缓存
 * @returns {string} - 缓存的value
*/
function getCache(key) {
   const value = localStorage.getItem(key)
   return value || null
}
/**
 * 获取前一篇id
 * @returns {string} - 前一篇id
*/
function getPrevId(tid, data) {
    for(let i = 0; i < data.length; i++) {
        if(`${data[i].tag}_${data[i].id}` === tid) {
            if(i<=0) return null
            return `${data[i-1].tag}_${data[i-1].id}`
        }
    }
    return null
 }
/**
 * 获取后一篇id
 * @returns {string} - 后一篇id
*/
function getNextId(tid,data) {
    for(let i = 0; i < data.length; i++) {
        if(`${data[i].tag}_${data[i].id}` === tid) {
            if(i>=data.length-1) return null
            return `${data[i+1].tag}_${data[i+1].id}`
        }
    }
    return null
 }

const util = {
    getScrollTop,
    getScreenWidth,
    setCache,
    getCache,
    getPrevId,
    getNextId
}
export default util