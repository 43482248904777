import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './features/counter/counterSlice'
import tagReducer from './features/tag/tagSlice'
import globalReducer from './features/global/globalSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    tag: tagReducer,
    global: globalReducer
  },
})